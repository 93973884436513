import React, { useEffect } from 'react';


const Tutor = () => {
  return (
    <iframe
      src="https://doc.gptapi.ai/"
      style={{ width: '100%', height: '85vh', border: 'none' }}
    />
  );
};

export default Tutor