import { Segment } from 'semantic-ui-react';
import { marked } from 'marked';
import React from 'react';
import "./index.css"

const markDownText = `## User协议

GptAPIUser服务协议

最后更新：2023-12-23

本协议（“协议”）由您（“User”或“开发者”）与GptAPI（以下简称“公司”、“我们”）签订。您使用我们的服务即表示同意这些条款。请仔细阅读。

1. 服务
   GptAPI是一个人工智能Model聚合器，提供API（“服务”）。API可以被开发者用来与多种大型语言Model、生成图像Model和生成3D对象Model互动。
2. GptAPI目前处于Test阶段；我们可能随时更改或停止服务的任何部分，包括特定Model的可用性和价格。
3. 如果需要移除官方的GptAPIModel，您可以期望以下情况：
   (1) 我们将通过GptAPI微信客服或者邮件提供通知。
   (2) 在一定Time内，我们将把对该Model的请求重定向到另一个价格和能力类似的Model。
   (3) 在至少2周的宽限期后，我们将停止重定向对该Model的请求，并返回404错误。
4. 义务
   禁止任何13岁以下的人使用GptAPI。您声明您已达到您所在司法管辖区的法定成年年龄，或者如果您未达到，您的父母或法定监护人必须同意这些GptAPI服务条款，并确认他们接受您代表他们的这些条款，并对您的使用承担责任。通过GptAPI访问的机器人可能产生不适合未成年人的内容。如果您代表他人或实体接受这些GptAPI服务条款，您确认您有权力将该人或实体Bind至这些条款。
5. 您同意不使用GptAPI来：
   (1) 侵犯他人权利或任何适用法律；
   (2) 违反这些条款或我们的政策，或GptAPI支持Model的第三方提供商的政策，这些政策可以通过OpenAI等访问文档找到；
   (3) 反向组装、反向编译、反编译、翻译或以其他方式试图发现Model、算法和系统的源代码或GptAPI及其底层技术；
   (4) 滥用、伤害、干扰、逆向工程或破坏GptAPI、Model或其底层技术，For example通过以欺诈或欺骗的方式访问或使用它们，引入恶意软件，或绕过任何保护措施；
   (5) 以禁止的方式使用GptAPI或Model，For example超过我们不时设定的任何速率限制，或反复创建账户而不支付访问费用；
   (6) 使用任何方法从GptAPI提取数据，包括网络抓取、网络收割或网络数据提取方法，除非被这些GptAPI服务条款允许；
   (7) 声称GptAPI生成的内容是人类生成的。
6. 支付
   开发者需要支付Model费用，Model的定价在Home可见，根据Model不同和是否适用折扣而有所不同。
   (1) User可以联系客服RechargeGet Redeem Code，或者使用海外支付卡来支付Model费用。
   (2) GptAPI使用Stripe安全处理非加密支付。请参阅他们的服务条款。Stripe的支付手续费用为3% + 单笔$0.3。User需自行承担交易费用
   (3) 如果我们收到您的支付的欺诈争议或任何与欺诈相关的退款，您将可能面临账号封禁，禁止对账户Balance进行处理
   (4) 积分除非遇到网站停运，BetaModelClose等不可抗力因素不可退款。
7. 如果您是企业并希望为每笔支付获得发票，Stripe将收取额外费用为每张发票0.4%，每笔交易上限为$2。
8. 我们可能随时更改定价。如果我们这样做，我们将在GptAPI网站或服务上发布更改通知。
9. 知识产权
   使用我们的服务不会将任何知识产权转让给User或开发者。所有权利、所有权和利益，包括任何软件、技术或流程，均归您在GptAPI中上传、发布或展示的所有材料，以及您对Model可访问的Prompt（或其他Enter）响应所收到的结果（“Model生成内容”），将被统称为“您的内容”。您保留对您的内容的所有权。
10. 争议解决
    任何因这些服务条款引起或与之相关的争议，或您与GptAPI根据这些服务条款的关系，均应通过具有约束力的仲裁解决。本协议受香港法律管辖，并依据其法律解释，不考虑任何法律冲突原则。
11. 隐私政策
    我们的隐私政策是一个单独的文件，解释了我们如何收集、使用和保护User信息。它构成这些服务条款的一部分，并在此通过引用并入。
12. 风险
    User应该充分了解大Model本身存在的不稳定性现象，包含OpenAI服务器偶尔宕机，API出现访问BUG等等，User应对风险自行做好兜底处理，GptAPI不承担对应损失的责任。

13. 责任
    GptAPI对因使用其服务引起或与之相关的任何损害、损失或问题不承担责任。
14. 修改
    我们可能随时修改这些服务条款，恕不另行通知。您继续使用我们的服务，即表示您接受并同意这些更改。
15. 终止
    我们可能随时出于任何原因终止您使用服务的权利，包括违反这些服务条款，在通知您后生效。您可以通过停止使用我们的服务随时终止您对服务的使用。
16. 终止后，所有权利和义务将被消除，除了根据其性质应当存续的权利和义务，包括但不限于所有权条款、保证声明、责任限制和争议解决条款。
`

const UserPrivacy = () => {

  return (
  <div style={{marginLeft: "16%", marginRight:"16%"}}>
      <Segment className="no-border-segment">
        <h1 className="text-center">User Agreement</h1>
        <p className="text-center">Last Updated: 2023-12-23</p>
        <h3>1. Services</h3>
        <p>GptAPI is an artificial intelligence model aggregator, providing an API (“Service”). The API can be used by developers to interact with a variety of large language models, image generation models, and 3D object generation models.</p>
        <p>GptAPI is currently in a testing phase; we may change or discontinue any part of the service at any time, including the availability and pricing of specific models.</p>
        <p>If an official GptAPI model needs to be removed, you can expect the following:
          <ol>
            <li> We will provide notification through GptAPI WeChat customer service or email.</li>
            <li>For a certain period of time, we will redirect requests for that model to another model with similar pricing and capabilities.</li>
            <li> After at least a 2-week grace period, we will stop redirecting requests to that model and return a 404 error.</li>
          </ol>
        </p>
        <h3>2. Obligations</h3>
        <p>
          The use of GptAPI is prohibited for anyone under the age of 13. You declare that you have reached the age of majority in your jurisdiction, or if not, your parents or legal guardian must agree to these GptAPI service terms and confirm they accept these terms on your behalf, and assume responsibility for your use. The robots accessed through GptAPI may generate content not suitable for minors. If you are accepting these GptAPI service terms on behalf of another person or entity, you confirm that you have the authority to bind that person or entity to these terms.
        </p>
        <p>
          You agree not to use GptAPI to:
          <ol>
            <li> Violate the rights of others or any applicable laws;</li>
            <li>Breach these terms or our policies, or the policies of third-party providers of GptAPI-supported models, which can be found in documentation accessible through entities like OpenAI;</li>
            <li> Reverse assemble, reverse compile, decompile, translate, or otherwise attempt to discover the source code of the models, algorithms, systems, or GptAPI and its underlying technology;</li>
            <li> Abuse, harm, interfere with, reverse engineer, or destroy GptAPI, models, or their underlying technology, for example by accessing or using them fraudulently or deceptively, introducing malware, or circumventing any protective measures;</li>
            <li> Use GptAPI or models in prohibited ways, for example exceeding any rate limits set by us from time to time, or repeatedly creating accounts without paying access fees;</li>
            <li> Extract data from GptAPI by any means, including web scraping, web harvesting, or web data extraction methods, unless permitted by these GptAPI service terms;</li>
            <li> Claim that content generated by GptAPI is human-generated.</li>
          </ol>
        </p>
        <h3>3. Payment</h3>
        <p>Developers need to pay for model fees, which are visible on the Home page and vary depending on the model and applicable discounts.
          <ol>
            <li> Users can contact customer service to recharge and get a redeem code, or use an international payment card to pay for model fees.</li>
            <li> If we receive a fraudulent dispute or refund related to your payment, your account may face a ban, prohibiting the processing of account balance.</li>
            <li> Credits are non-refundable unless due to force majeure such as website shutdown, Beta Model closure, etc.</li>
          </ol>
          <p>We may change pricing at any time. If we do, we will post a notice of the changes on the GptAPI website or service.</p>
        </p>

        <h3>4. Intellectual Property</h3>
        <p>
          Using our services does not transfer any intellectual property rights to users or developers. All rights, ownership, and interests in any software, technology, or processes, including all materials you upload, post, or display in GptAPI, as well as the results you receive in response to prompts (or other inputs) <a href="https://glasp.co/b2t42cj50fh4ig72" style={{color: "inherit", textDecoration: "none", fontWeight: "inherit"}}> accessible</a> to the model (“Model-generated Content”), collectively referred to as “Your Content”, are retained by you. You retain all rights to Your Content.
        </p>
        <h3>5. Dispute Resolution</h3>
        <p>
          Any dispute arising from or related to these service terms, or your relationship with GptAPI under these service terms, shall be resolved through binding arbitration. This agreement is governed by and construed in accordance with the laws of Hong Kong, without regard to its conflict of law principles.
        </p>
        <h3>6. Privacy Policy</h3>
        <p>Our privacy policy is a separate document that explains how we collect, use, and protect user information. It forms part of these service terms and is incorporated herein by reference.</p>
        <h3>7. Risks</h3>
        <p>
          Users should be fully aware of the inherent instability of large models, including occasional downtime of OpenAI servers, API access bugs, etc. Users should prepare for these risks themselves, and GptAPI is not responsible for any corresponding losses.
        </p>
        <h3>8. Liability</h3>
        <p>GptAPI is not liable for any damages, losses, or issues arising from or related to the use of its services.</p>
        <h3>9. Modifications</h3>
        <p>We may modify these service terms at any time without further notice. Your continued use of our services indicates your acceptance and agreement to these changes.</p>
        <h3>10. Termination</h3>
        <p>We may terminate your right to use the services at any time for any reason, including a breach of these service terms, effective upon notification to you. You may terminate your use of the services at any time by ceasing to use them.</p>
        <p>After termination, all rights and obligations will be extinguished, except for rights and obligations that by their nature should survive, including but not limited to ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution terms.</p>
      </Segment>
  </div>
  )
};

export default UserPrivacy