import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, getLogo, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';
import "../index.css"
import { toast } from 'react-toastify';
import { UserContext } from '../context/User';

const sendCodeButtonStyle = {
  // padding: '10px',
  // marginLeft: '25px',
  fontSize: '15px',
  backgroundColor: 'grey', // Grey-black background
  border: 'none', // Remove default border
  color: '#fff', // Text color
  // borderRadius: '5px', // Rounded corners
  height: '38px',
  width: '120px', // Slightly larger width
  cursor: 'pointer', // Changes cursor to a hand symbol on hover
  textAlign: 'center',
  display: 'initial'
};

const registerButtonStyle = {
  padding: '10px',
  marginBottom: '16px',
  marginTop: '30px',
  fontSize: '18px',
  backgroundColor: '#333', // Grey-black background
  border: 'none', // Remove default border
  color: '#fff', // Text color
  borderRadius: '5px', // Rounded corners
  // height: '42px',
  // width: '90px', // Slightly larger width
  cursor: 'pointer', // Changes cursor to a hand symbol on hover
};

const RegisterForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
    verification_code: ''
  });
  const { username, password, password2, email } = inputs;
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [userState, userDispatch] = useContext(UserContext);

  const logo = getLogo();
  let affCode = new URLSearchParams(window.location.search).get('aff');
  if (affCode) {
    localStorage.setItem('aff', affCode);
  }

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setShowEmailVerification(status.email_verification);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
  });

  let navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    inputs.username = inputs.email;
    if (password.length < 8) {
      showInfo('The length of password cannot lesser than 8 ');
      return;
    }
    if (password !== password2) {
      showInfo('Two input password not same');
      return;
    }
    if (inputs.email && inputs.password) {
      if (turnstileEnabled && turnstileToken === '') {
        showInfo('Check User Environment!');
        return;
      }
      setLoading(true);
      if (!affCode) {
        affCode = localStorage.getItem('aff');
      }
      inputs.aff_code = affCode;
      const res = await API.post(
        `/api/user/register?turnstile=${turnstileToken}`,
        inputs
      );
      const { success, message, data } = res.data;
      if (success) {
        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        navigate('/topup');
        showSuccess('Register success！');
      } else {
        showError(message);
      }
      setLoading(false);
    }
  }

  const sendVerificationCode = async () => {
    console.log("sendVerificationCode")
    if (inputs.email === '') {
      toast.error('Please input email.');
      return;
    }
    if (turnstileEnabled && turnstileToken === '') {
      showInfo('Check User Environment!');
      return;
    }

    setLoading(true);
    const res = await API.get(
      `/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      // 设置倒计时时间（60秒）
      setTimeLeft(60);
      showSuccess('Send code success, please check your email');
    } else {
      showError(message);
    }
    setLoading(false);
  };

  // 设置初始状态
  const [timeLeft, setTimeLeft] = useState(null);


  // 使用useEffect来处理倒计时逻辑
  useEffect(() => {
    // 如果没有剩余时间，不做任何事情
    if (!timeLeft) return;

    // 每秒减少时间
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // 清理定时器
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <Grid textAlign='center' style={{ marginTop: '48px' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={logo} size={"mini"} />
        <Header as='h2' color='' textAlign='left' >
          Welcome GptAPI
        </Header>
        <p style={{textAlign: 'left'}}>
          Sign up with your email
        </p>
        <Form size='large'>
          <Segment textAlign={"left"}>

            <Form.Input
              fluid
              // icon='mail'
              // iconPosition='left'
              // placeholder='Enter Email Address'
              onChange={handleChange}
              name='email'
              label='Email'
              type='email'
            />

            {/*<Form.Input*/}
            {/*  fluid*/}
            {/*  // icon='user'*/}
            {/*  // iconPosition='left'*/}
            {/*  // placeholder='Enter昵称'*/}
            {/*  onChange={handleChange}*/}
            {/*  name='username'*/}
            {/*/>*/}

            <Form.Input
              fluid
              // icon='lock'
              // iconPosition='left'
              // placeholder='Enter password, at least 8 characters and up to 20 characters'
              onChange={handleChange}
              name='password'
              label='Password'
              type='password'
            />
            <Form.Input
              fluid
              // icon='lock'
              // iconPosition='left'
              // placeholder='Enter password, at least 8 characters and up to 20 characters'
              onChange={handleChange}
              name='password2'
              label='Confirm Password'
              type='password'
            />

            <>
              <Form.Input
                fluid
                // icon='mail'
                // iconPosition='left'
                // placeholder='Enter Email Address'
                // className="my-custom-input"
                onChange={handleChange}
                name='verification_code'
                label='Verification Code'
                action={
                    <Button onClick={sendVerificationCode} disabled={timeLeft>0 || loading}  style={sendCodeButtonStyle}>
                      {timeLeft <= 0? "Send Code": timeLeft}
                    </Button>
                }
              />

              {/*<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>*/}

              {/*    <Form.Input*/}
              {/*      fluid*/}
              {/*      onChange={handleChange}*/}
              {/*      name='verification_code'*/}
              {/*      label='Verification Code'*/}
              {/*    />*/}
              {/*  <Button*/}
              {/*    onClick={sendVerificationCode}*/}
              {/*    disabled={loading}*/}
              {/*    style={sendCodeButtonStyle} // 添加一些左边距以分隔按钮和输入框*/}
              {/*  >*/}
              {/*    Send Code*/}
              {/*  </Button>*/}
              {/*</div>*/}

            </>

            {turnstileEnabled ? (
              <Turnstile
                sitekey={turnstileSiteKey}
                onVerify={(token) => {
                  setTurnstileToken(token);
                }}
              />
            ) : (
              <></>
            )}
            <Button
              fluid
              size='large'
              onClick={handleSubmit}
              loading={loading}
              style={registerButtonStyle}
            >
              Sign up
            </Button>
            <p style={{fontSize: "13px", marginTop: "16px", marginBottom: "16px"}}>
              Already have an account？
              <Link to='/login' style={{color: '#72727b', fontWeight: "600"}}>
                Login
              </Link>
            </p>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default RegisterForm;